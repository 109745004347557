import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StepperComponent } from './stepper/stepper.component';
import { KaartenComponent } from './kaarten/kaarten.component';
import { DesignComponent } from './design/design.component';
import { ProductenComponent } from './producten/producten.component';

const routes: Routes = [
  { path : '', component : HomeComponent },
  { path: 'dashboard', component: DashboardComponent },
  { path: 'stepper', component: StepperComponent },
  { path: 'design', component: DesignComponent },
  { path: 'kaarten', component: KaartenComponent },
  { path: 'producten', component: ProductenComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
