import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './kaarten.component.html',
  styleUrls: ['./kaarten.component.scss']
})

export class KaartenComponent {

}
