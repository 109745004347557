import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './producten.component.html',
  styleUrls: ['./producten.component.scss']
})

export class ProductenComponent {

}
