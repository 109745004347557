import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { StepperComponent } from './stepper/stepper.component';
import { KaartenComponent } from './kaarten/kaarten.component';
import { DesignComponent } from './design/design.component';
import { ProductenComponent } from './producten/producten.component';

import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

// import { QRCodeComponent } from 'ngx-qrcode';
// import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';

import { QRCodeModule } from 'angularx-qrcode';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DashboardComponent,
    StepperComponent,
    KaartenComponent,
    DesignComponent,
    ProductenComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    QRCodeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
