import { Component, OnInit } from '@angular/core';
import Stepper from 'bs-stepper';
import { ApiService } from '../api.service';
import { Product } from '../product';
import { Setting } from '../setting';

@Component({
  selector: 'app-home',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss']
})

export class StepperComponent implements OnInit {

  products: Product[];
  settings: Setting[];
  private stepper: Stepper;

  public cardType = '';
  public productAmount = [];
  public bestellingDone = false;
  public paid = false;
  public totalPriceProducts = 0.0;
  public totalPriceOrder = 0.0;

  constructor(private apiService: ApiService) { }

  ngOnInit() {
    let self = this;
    this.stepper = new Stepper(document.querySelector('#stepper1'), {
      linear: false,
      animation: true
    });
    this.apiService.readProducts().subscribe((products: Product[])=>{
      this.products = products;
      products.forEach(function(value){
        self.productAmount[value.name] = 0;
        console.log(value);
      });
      console.log(this.products);
    });
    this.apiService.readSettings().subscribe((settings: Setting[])=>{
      this.settings = settings;
      console.log(this.settings);
    });
  }

  next() {
    this.stepper.next();
  }

  onSubmit() {
    return false;
  }

  setCardType(type) {
    this.cardType = type;
  }

  changeProductAmount(card, amount) {
    this.productAmount[card] += amount;
    this.updateTotalPrice();
  }

  updateTotalPrice() {
    let self = this;
    let price = 0;
    this.products.forEach(function(product){
      price += product.price * self.productAmount[product.name];
    });
    this.totalPriceProducts = price;
    this.totalPriceOrder = this.totalPriceProducts + parseInt(this.settings['shippingPrice']);
  }

  createOrder() {
    console.log('rrr');
    this.bestellingDone = true;
    //TODO API CALL SAVE ORDER
  }

  hasPaid() {
    this.paid = true;
  }
}
